

































































import './styles/ProjectTeamHeader.scss';
import './styles/ProjectTeamHeaderAdaptive.scss';
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectTeamActions, ProjectTeamMutations } from '@store/modules/project-team/Types';
import { IMemberRole, IProject } from '@store/modules/project/Interfaces';
import { IProjectTeamPopupsState } from '@store/modules/project-team/modules/popups/Interfaces';
import { PopupActions } from '@store/Popup';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { IViewMode } from "@/interfaces/IViewMode";

const NSProjectTeamPopups = namespace('storeProjectTeam/storeProjectTeamPopups');
const NSProjectTeam = namespace('storeProjectTeam');
const NSProject = namespace('storeProject');
const NSUser = namespace('storeUser');

@Component({
    name: 'ProjectTeamHeader',

    components: {
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseRespondsCounter: () => import('@components/BaseRespondsCounter'),
        ButtonFavorite: () => import('@components/buttons/ButtonFavorite.vue'),
        PopupFiltersTeam: () => import('@components/popups/PopupFiltersTeam.vue'),
        BaseViewMode: () => import('@components/BaseViewMode/BaseViewMode.vue'),
        FiltersPreview: () => import('@components/FiltersPreview/FiltersPreview.vue'),
    },
})
export default class ProjectTeamHeader extends Vue {
    $refs!: {
        filterPopup: HTMLFormElement,
        filtersPreview: HTMLFormElement,
    }

    @Prop({}) items!: any[];
    @Prop({}) filteredItemsLength!: number;

    @NSProjectTeam.Getter('filters') filters!: any[];
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('memberRoles') memberRoles!: IMemberRole[];
    @NSProjectTeam.Getter('currentViewMode') currentViewMode!: IViewMode | null;
    @NSProjectTeam.Getter('isAnyMemberSelected') isAnyMemberSelected!: boolean;
    @NSProjectTeam.Action(ProjectTeamActions.A_APPLY_ROLE_TO_SELECTED)
    applyRoleToSelected!: (
        payload: { role: IMemberRole, projectId: number, }
    ) => void;
    @NSProjectTeam.Mutation(ProjectTeamMutations.M_SET_CURRENT_VIEW_MODE)
    setCurrentViewMode!: (viewMode: IViewMode | null) => void;
    @NSProjectTeamPopups.Getter('addMemberPopupToggler') addMemberPopupToggler!: boolean;
    @NSProjectTeamPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IProjectTeamPopupsState>
    ) => void;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;

    selectedRole = {} as IMemberRole;
    viewsMode: IViewMode[] = ['simple'];

    @Watch('selectedRole')
    watchSelectedRole() {
        if (!this.isAnyMemberSelected) return;
        this.applyRoleToSelected({ role: this.selectedRole, projectId: this.projectId });
    }

    setFiltersPreview(filtersData) {
        this.$refs.filtersPreview.setFiltersData(filtersData);
    }

    resetFilters(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }

    openFilters() {
        this.$refs.filterPopup.openPopup();
    }

    toggleAddingPopup() {
        this.togglePopup({
            popupToggler: 'addMemberPopupToggler',
            state: !this.addMemberPopupToggler,
        });
    }

    goTo(path: string) {
        this.$router.push({ path });
    }
}
